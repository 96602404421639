import styled from "styled-components";
import { Colors } from "../../constants/Colors";

export const Container = styled.div`
    display: flex;
    border-radius: 4px;
    height: 1.25rem;
    width: 85%;
    overflow: hidden;
    color: ${Colors.commons.white};
`;

export const Position = styled.div`
    background-color: ${Colors.primary[100]};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    font-weight: bold;
    font-size: 0.625rem;
`;

export const Plate = styled.div`
    background-color: ${Colors.primary[80]};
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.75rem;
`;