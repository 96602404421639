export const logout = (navigate: (path: string) => void) => {
    sessionStorage.removeItem("authToken");
    navigate("/login");
};

export const saveUserInfos = (username: string, station: string, token: string) => {
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("userStation", station);
    sessionStorage.setItem("authToken", token);
};

export const getUserInfos = () => {
    const obj = {
        username: sessionStorage.getItem("userName"),
        station: sessionStorage.getItem("userStation"),
        token: sessionStorage.getItem("authToken"),
    };
    return obj;
};