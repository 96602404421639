import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const publicApi = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    withCredentials: false
});

export const privateApi = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

privateApi.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem("authToken");
        if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            // console.log("Headers da requisição:", config.headers);
            // console.log(config.data);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
