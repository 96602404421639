import React from 'react';
import { Container, Logo, LogoutButton } from './HeaderStyles';
import { SignOut } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../utils/authMethods';

const Header: React.FC = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        logout(navigate);
    };

    return (
        <Container>
            <Logo src={require('../../assets/images/logo-super-ducha.png')} alt="Super Ducha" />
            <LogoutButton onClick={handleLogout}>
                <SignOut size={24} />
            </LogoutButton>
        </Container>
    );
};

export default Header;
