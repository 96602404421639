import { publicApi } from "../lib/axiosConfig";
import { saveUserInfos } from "../utils/authMethods";

export const auth = async (login: string, password: string) => {
  try {
    const response = await publicApi.post("/user/login", {
      login,
      password,
    });
    saveUserInfos(
      response.data.full_name !== undefined ? response.data.full_name : "Nome de usuário não encontrado",
      response.data.station !== undefined ? response.data.station : "",
      response.data.token
    );
    return response.data;
  } catch (error) {
    throw new Error("Credenciais inválidas");
  }
};
