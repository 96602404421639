import { privateApi } from "../lib/axiosConfig";

export const getQueue = async (station: string) => {
    try {
        const response = await privateApi.get(`/stations/queue?station=${station}`);
        return response.data;
    } catch (error: any) {
        console.error("Erro ao buscar fila: ", error);
        throw error;
    }
}