import { Colors } from "../../constants/Colors";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const Logo = styled.img`
    width: 80vw;
    max-width: 300px;
    height: auto;

    @media (min-width: 768px) {
        width: 50vw;
        max-width: 500px;
    }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  margin-top: 5vh;
  @media (min-width: 768px) {
    width: 80vw;
    max-width: 500px;
    margin-top: 10vh;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 14px;
  color: ${Colors.secondary[100]};
  margin-bottom: 10px;
  text-align: center;
`;
