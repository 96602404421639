import { privateApi } from "../lib/axiosConfig";

export const createOrder = async (
  washingTypeIds: number[],
  customer: string
) => {
  try {
    const response = await privateApi.post(
      "/orders",
      { washingTypeIds, customer },
    );
    return response.data;
  } catch (error: any) {
    console.error("Erro ao confirmar venda: ", error);
    throw error;
  }
};
