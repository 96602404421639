import React from "react";
import { Container, Position, Plate } from "./PositionBadgeStyles";

interface PositionBadgeProps {
  position: number;
  plate: string;
}

const PositionBadge: React.FC<PositionBadgeProps> = ({ position, plate }) => {
  return (
    <Container>
      <Position>{position}</Position>
      <Plate>{plate}</Plate>
    </Container>
  );
};

export default PositionBadge;
