import styled, { keyframes } from "styled-components";
import { Colors } from "../../constants/Colors";

export const ButtonWrapper = styled.button`
  width: 100%;
  max-width: 200px;
  padding: 10px 20px;
  border-radius: 25px;
  border: none;
  background-color: ${Colors.primary[100]};
  color: ${Colors.commons.white};
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${Colors.primary[80]};
  }

  &:active {
    background-color: ${Colors.primary[60]};
  }

  &:disabled {
    background-color: ${Colors.primary[40]};
    cursor: not-allowed;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 3px solid ${Colors.primary[20]};
  border-top: 3px solid ${Colors.commons.white};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
`;
